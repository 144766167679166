import { useDialogState } from '@gain/utils/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { generatePath, useHistory } from 'react-router'
import * as yup from 'yup'

import { InputFieldText, InputFormProvider, useInputFormAPI } from '../../../common/input-fields'
import { CUSTOMER_GENERAL_PATH } from '../../route-customer/route-customer-path'

export default function CreateCustomerDialog() {
  const [open, handleShow, handleClose] = useDialogState()
  const history = useHistory()

  const inputFormAPI = useInputFormAPI({
    createMethod: 'customer.createCustomer',
    defaultValues: {
      name: null,
    },
    validationSchema: {
      name: yup.string().required(),
    },
  })

  const handleSubmit = inputFormAPI.form.handleSubmit(async (values) => {
    await inputFormAPI.create({ partial: values }, (customer) => {
      history.push(
        generatePath(CUSTOMER_GENERAL_PATH, {
          id: customer.id,
        })
      )
    })
  })

  return (
    <>
      <Button
        onClick={handleShow}
        variant={'contained'}>
        New
      </Button>

      <Dialog
        maxWidth={'xs'}
        onClose={handleClose}
        open={open}
        fullWidth>
        <DialogTitle>Create a new customer</DialogTitle>
        <DialogContent>
          <InputFormProvider form={inputFormAPI.form}>
            <Stack py={1}>
              <InputFieldText
                label={'Name'}
                name={'name'}
              />
            </Stack>
          </InputFormProvider>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={inputFormAPI.busy}
            onClick={handleClose}
            variant={'text'}>
            Cancel
          </Button>
          <LoadingButton
            loading={inputFormAPI.busy}
            onClick={handleSubmit}
            variant={'contained'}>
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
