import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec, DefaultReactSuggestionItem } from '@blocknote/react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Stack from '@mui/material/Stack'

import { blockEditorSchema } from '../../note-editor-schema'
import NodeEditorFileDownload from './note-editor-file-download'
import NodeEditorFileUpload from './note-editor-file-upload'

export const NOTE_EDITOR_FILE_TYPE = 'file'

export const NoteEditorFile = createReactBlockSpec(
  {
    type: NOTE_EDITOR_FILE_TYPE,
    propSchema: {
      fileName: {
        default: '',
      },
      fileId: {
        default: 0,
      },
    },
    content: 'inline',
  },
  {
    render: ({ block, editor }) => (
      <Stack
        contentEditable={false}
        width={'100%'}>
        {!block.props.fileId && (
          <NodeEditorFileUpload
            onFileUpload={(fileId, fileName) =>
              editor.updateBlock(block, {
                props: { fileId, fileName },
              })
            }
          />
        )}

        {Boolean(block.props.fileId) && (
          <NodeEditorFileDownload
            fileId={block.props.fileId}
            fileName={block.props.fileName}
          />
        )}
      </Stack>
    ),
  }
)

// Slash menu item to insert an Alert block
export function noteEditorFileSlashCommand(
  editor: typeof blockEditorSchema.BlockNoteEditor
): DefaultReactSuggestionItem {
  return {
    title: 'File',
    subtext: 'Upload file',
    onItemClick: () => {
      insertOrUpdateBlock(editor, {
        type: NOTE_EDITOR_FILE_TYPE,
      })
    },
    aliases: ['file', 'upload'],
    group: 'Media',
    icon: <AttachFileIcon />,
  }
}
