import { Option } from '@gain/rpc/shared-model'

import { AssetUpdateReason } from './lib/rpc-cms-model'

export interface AssetUpdateReasonOption extends Option<AssetUpdateReason> {
  color: string
}

export const ASSET_UPDATE_REASON_OPTIONS: ReadonlyArray<AssetUpdateReasonOption> = [
  {
    value: AssetUpdateReason.NewAR,
    label: 'New AR',
    color: '#2E7D32',
  },
  {
    value: AssetUpdateReason.CheckForNewAR,
    label: 'Check for new AR',
    color: '#26C6DA',
  },
  {
    value: AssetUpdateReason.PeriodicReview,
    label: 'Periodic review',
    color: '#EF6C00',
  },
  {
    value: AssetUpdateReason.FullNewsUpdate,
    label: 'Full news update',
    color: '#9C27B0',
  },
  {
    value: AssetUpdateReason.PartialNewsUpdate,
    label: 'Partial news update',
    color: '#2055F5',
  },
  {
    value: AssetUpdateReason.PropDataInputs,
    label: 'Prop data inputs',
    color: '#C0CA33',
  },
  {
    value: AssetUpdateReason.Other,
    label: 'Other',
    color: '#BDBDBD',
  },
]
