import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getRpcParams } from '../../util/getOptions'
import { hasMoreFiltered } from '../../util/hasMore'
import { LIST_CURRENCIES_METHOD } from '../../util/methods'
import {
  loadCurrencies,
  loadCurrenciesSuccess,
  LOAD_CURRENCIES,
  LOAD_CURRENCIES_CANCELLED,
  LOAD_CURRENCIES_SUCCESS,
} from '../action/loadCurrencies'
import { rpc } from './rpc'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(LOAD_CURRENCIES),
      mergeMap((action) =>
        rpc(
          action,
          LIST_CURRENCIES_METHOD,
          getRpcParams(state$.value.loadCurrencies, { limit: 250 })
        ).pipe(
          map((response) => loadCurrenciesSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(LOAD_CURRENCIES_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(LOAD_CURRENCIES_SUCCESS),
      filter(() => hasMoreFiltered(state$.value.loadCurrencies)),
      map(() => loadCurrencies(state$.value.loadCurrencies.page + 1))
    )
  )
