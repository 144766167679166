import { useFileUpload } from '@gain/api/cms/hooks'
import Alert, { alertClasses } from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { PropsWithChildren, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { ACCEPT_ALL_FILES } from '../../../common/input-fields/input-file'
import { BlockNoteEditor } from './'
import { NOTE_EDITOR_FILE_TYPE } from './blocks/file'

export interface NodeEditorFileUploadProps {
  enabled?: boolean
  editor: BlockNoteEditor
}

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) => !['isDragActive'].includes(prop.toString()),
})<{ isDragActive: boolean }>(({ isDragActive }) => ({
  height: '100%',

  borderStyle: 'dashed',
  borderWidth: '1px',
  borderColor: isDragActive ? 'black' : 'transparent',
}))

export const StyledAlert = styled(Alert)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  borderRadius: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  borderTop: 'none',
  borderRight: 'none',
  borderLeft: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,

  [`& .${alertClasses.message}`]: {
    padding: theme.spacing(1.25),
    ...theme.typography.caption,
  },
  [`& .${alertClasses.icon}`]: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  [`& .${alertClasses.action}`]: {
    marginRight: theme.spacing(1),
  },
}))

export default function NoteEditorDropFileContainer({
  editor,
  enabled,
  children,
}: PropsWithChildren<NodeEditorFileUploadProps>) {
  const [uploading, setUploading] = useState<boolean>(false)
  const uploadFile = useFileUpload()

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        if (uploading || acceptedFiles.length === 0) {
          return
        }

        setUploading(true)

        for (const acceptedFile of acceptedFiles) {
          const fileId = await uploadFile(acceptedFile, { secure: true })

          if (fileId) {
            // Append the block
            editor.insertBlocks(
              [
                {
                  type: NOTE_EDITOR_FILE_TYPE,
                  props: {
                    fileId,
                    fileName: acceptedFile.name || 'unknown',
                  },
                },
              ],
              editor.document[editor.document.length - 1],
              'after'
            )
          }
        }
      } finally {
        setUploading(false)
      }
    },
    noClick: true,
    accept: ACCEPT_ALL_FILES,
    disabled: !enabled,
  })

  return (
    <StyledRoot
      {...getRootProps()}
      isDragActive={isDragActive}>
      {uploading && (
        <StyledAlert
          action={<CircularProgress size={16} />}
          icon={false}
          variant={'outlined'}>
          Uploading files
        </StyledAlert>
      )}

      {children}
    </StyledRoot>
  )
}
