import { useRpcClient } from '@gain/api/swr'
import { FileStatus, RpcMethodMap } from '@gain/rpc/cms-model'
import { apiDomain } from '@gain/rpc/utils'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import { StyledAlert } from './note-editor-file-upload'

export interface NodeEditorFileDownloadProps {
  fileId: number
  fileName: string
}

export default function NodeEditorFileDownload({ fileId, fileName }: NodeEditorFileDownloadProps) {
  const [validatingFile, setValidatingFile] = useState(false)
  const rpcClient = useRpcClient<RpcMethodMap>()
  const { enqueueSnackbar } = useSnackbar()

  const handleDownloadFile = async () => {
    try {
      setValidatingFile(true)

      const { status, url } = await rpcClient({
        method: 'data.getFileInfo',
        params: {
          id: fileId,
        },
      })

      if (status === FileStatus.Safe || status === FileStatus.Generated) {
        window.open(`${apiDomain}${url}`, '_blank')
      } else if (status === FileStatus.Malicious) {
        enqueueSnackbar('File contains virus or malware and will not be downloadable in the app.', {
          variant: 'error',
        })
      } else if (status === FileStatus.UnscannedPriority) {
        enqueueSnackbar('File is currently being scanned for viruses with priority.', {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar(
          'File is being scanned for viruses as a background job. This might take a while.',
          {
            variant: 'warning',
          }
        )
      }
    } finally {
      setValidatingFile(false)
    }
  }

  return (
    <StyledAlert
      action={
        <IconButton
          disabled={validatingFile}
          onClick={handleDownloadFile}
          size={'small'}>
          <CloudDownloadIcon />
        </IconButton>
      }
      icon={<AttachFileIcon />}
      severity={'info'}
      variant={'standard'}>
      {fileName}
    </StyledAlert>
  )
}
