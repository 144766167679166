import Typography from '@gain/components/typography'
import { useOpenLink } from '@gain/utils/router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { styled, SxProps } from '@mui/material/styles'
import { MouseEvent } from 'react'
import { generatePath } from 'react-router'

// Don't use the shortened import, it causes strange runtime issues
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../routes/route-asset/route-asset-path'
import { CREDIT_GENERAL_PAGE_PATH } from '../routes/route-credits/route-credit/route-credit-path'
import { LENDER_PROFILE_PAGE_PATH } from '../routes/route-lenders/route-lender/route-lender-path'

// TODO(@remy): We had a discussion if this component belongs in features,
//  common or routes. This component imports router logic, and therefor
//  domain specific logic AND we want to prevent features from importing files
//  from routes, and prevent files in common to import from features. This makes
//  this a bit of a tricky component. We decided to revisit this when we decide
//  to upgrade the react-router to the latest version, and with this, group all
//  routes into a single file instead of having them spread all over the place.
//  When we do this, please also take the time to find a logical place for this
//  component.

const StyledCmsLink = styled(Typography)({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

type LinkType = 'asset' | 'lender' | 'credit'

const LinkMap: Record<LinkType, string> = {
  asset: ROUTE_ASSET_KEY_FACTS_PATH,
  lender: LENDER_PROFILE_PAGE_PATH,
  credit: CREDIT_GENERAL_PAGE_PATH,
}

interface CmsLinkProps {
  id: number | string | null | undefined
  type: LinkType
  label: string | undefined | null
  sx?: SxProps
}

export default function CmsLink({ id, type, label, sx }: CmsLinkProps) {
  const openLink = useOpenLink()

  if (!id || !label) {
    return null
  }

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    openLink(generatePath(LinkMap[type], { id }), e)
  }

  return (
    <StyledCmsLink
      color={'text.primary'}
      onClick={handleClick}
      sx={sx}
      variant={'body2'}
      noWrap>
      {label}
      <ChevronRightIcon />
    </StyledCmsLink>
  )
}
