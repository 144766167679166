import { useFileUpload } from '@gain/api/cms/hooks'
import { useRpcClient } from '@gain/api/swr'
import { ImportInvestorFundsResult, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export default function ImportInvestorFundsDialog(props: DialogProps) {
  const upload = useFileUpload()
  const rpc = useRpcClient<RpcMethodMap>()
  const [result, setResult] = useState<ImportInvestorFundsResult | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [visibleTab, setVisibleTab] = useState<number>(0)
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length !== 1) {
        return
      }
      setLoading(true)
      setError(null)
      const fileId = await upload(acceptedFiles[0], { secure: true })

      if (!fileId) {
        // Should not happen but added just in case
        throw new Error('Failed to upload file')
      }

      try {
        const response = await rpc<'data.importInvestorFunds'>({
          method: 'data.importInvestorFunds',
          params: {
            fileId,
          },
        })
        setResult(response)
      } catch (e) {
        if (isJsonRpcError(e)) {
          setError(e.message)
        }
      }
      setLoading(false)
    },
    [upload, rpc]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  })

  useEffect(() => {
    if (!props.open) {
      setLoading(false)
      setResult(null)
      setVisibleTab(0)
    }
  }, [props.open])

  return (
    <Dialog {...props}>
      <DialogTitle>Import investor funds</DialogTitle>
      <DialogContent>
        {result === null && (
          <Stack gap={2}>
            <Typography variant={'body2'}>
              Update investor fund performance metrics given a CSV file. Download the example below
              to view the formatting of the CSV file.
            </Typography>

            {error !== null && <Alert severity={'error'}>{error}</Alert>}

            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}>
              {loading && <CircularProgress size={16} />}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <Button
                    disabled={loading}
                    variant={'contained'}>
                    Select file
                  </Button>
                )}
              </div>
              <Button
                href={'/excel/import-investor-fund-performance-metrics-example.csv'}
                target={'_blank'}>
                Download example
              </Button>
            </Stack>
          </Stack>
        )}
        {result !== null && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                aria-label={'basic tabs example'}
                onChange={(_, newValue) => setVisibleTab(newValue)}
                value={visibleTab}>
                <Tab
                  label={'Imported'}
                  value={0}
                />
                <Tab
                  label={'Skipped'}
                  value={1}
                />
              </Tabs>
            </Box>
            {visibleTab === 0 && (
              <Stack
                gap={1}
                sx={{ py: 2 }}>
                <Typography variant={'body2'}>
                  Imported fund metrics for {result.importedItems.length} of {result.rowCount}{' '}
                  funds.{' '}
                </Typography>
                <Table
                  size={'small'}
                  sx={{ border: '1px solid black', borderColor: 'divider', borderRadius: 4 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Row</TableCell>
                      <TableCell>Fund name</TableCell>
                      <TableCell>Fund ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.importedItems.map((item) => (
                      <TableRow key={item.rowIndex}>
                        <TableCell>{item.rowIndex}</TableCell>
                        <TableCell>{item.fundName}</TableCell>
                        <TableCell>{item.fundId}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            )}
            {visibleTab === 1 && (
              <Stack
                gap={1}
                sx={{ py: 2 }}>
                <Typography variant={'body2'}>
                  Skipped import of fund metrics for {result.skippedItems.length} of{' '}
                  {result.rowCount} funds.
                </Typography>
                <Table
                  size={'small'}
                  sx={{ border: '1px solid black', borderColor: 'divider', borderRadius: 4 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Row</TableCell>
                      <TableCell>Fund name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {result.skippedItems.map((item) => (
                      <TableRow key={item.rowIndex}>
                        <TableCell>{item.rowIndex}</TableCell>
                        <TableCell>{item.fundName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
