// Our schema with inline content specs, which contain the configs and
// implementations for inline content  that we want our editor to use.
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'

import { NoteEditorFile } from './blocks/file'

export const blockEditorSchema = BlockNoteSchema.create({
  blockSpecs: {
    // Adds all default inline content.
    ...defaultBlockSpecs,
    file: NoteEditorFile,
  },
})
