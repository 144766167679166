import { ChipOption, InvestorStatus } from '@gain/rpc/shared-model'

export type SwrRowStatusType = InvestorStatus

export const SWR_ROW_STATUS_OPTIONS: ReadonlyArray<ChipOption<SwrRowStatusType>> = [
  {
    label: 'Archived',
    value: InvestorStatus.Archived,
    color: 'error',
  },
  {
    label: 'Published',
    value: InvestorStatus.Published,
    color: 'success',
  },
  {
    label: 'New',
    value: InvestorStatus.New,
    color: 'info',
  },
  {
    label: 'Review',
    value: InvestorStatus.Review,
    color: 'info',
  },
]
