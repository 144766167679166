import * as yup from 'yup'

export function yupNumber(): yup.NumberSchema {
  return yup.number().transform((value) => (isNaN(value) ? null : value))
}

export function yupUrl(): yup.StringSchema {
  return yup.string().trim().url()
}

export function yupPartialDate() {
  return yup.object({
    year: yupNumber().nullable(),
    month: yupNumber().nullable(),
  })
}

export function yupLinkedInUrl(): yup.StringSchema {
  return yupUrl().test(
    'is-linkedin',
    'should be a LinkedIn URL',
    (value) => !value || /linkedin\.com/.test(value)
  )
}

export function yupLinkedInCompanyUrl(): yup.StringSchema {
  return yup
    .string()
    .url()
    .trim()
    .test(
      'is-linkedin-company-url',
      'should be a LinkedIn company URL',
      (value) => !value || /^https:\/\/www.linkedin.com\/company\/[^/]+\/?$/.test(value)
    )
    .transform((value) => {
      if (typeof value === 'string') {
        // strip trailing slash
        return value.endsWith('/') ? value.slice(0, -1) : value
      }

      return value
    })
}

export function yupStringMaxChars(maxCharacters: number): yup.StringSchema {
  return yup
    .string()
    .test(
      'len',
      `Max ${maxCharacters} characters`,
      (val) => (val?.toString()?.length ?? 0) <= maxCharacters
    )
    .trim()
}
