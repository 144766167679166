import Flag from '@gain/components/flag'
import OverflowContainer, { overflowContainerClasses } from '@gain/components/overflow-container'
import Typography from '@gain/components/typography'
import { AssetAssignee, AssetListItem, AssetProject, AssetUpdateReason } from '@gain/rpc/cms-model'
import { ASSET_UPDATE_REASON_OPTIONS, AssetUpdateReasonOption } from '@gain/rpc/cms-model'
import { AssetProfileType, getOption } from '@gain/rpc/shared-model'
import { listFilter } from '@gain/rpc/utils'
import WarningIcon from '@mui/icons-material/Warning'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { capitalize } from '@mui/material/utils'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { ReactElement, ReactNode } from 'react'

import InputForm from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridBulkContainer, SwrDataGridProps } from '../../common/swr-data-grid'
import AssetActions from '../../routes/route-asset/asset-actions'
import CmsLink from '../cms-link'
import { renderAssetAssigneeEditCell } from './asset-assignee-data-grid'
import AssetBulkDataGrid from './asset-bulk-data-grid'
import { renderAssetProjectEditCell } from './asset-project-data-grid'
import AssetStatusDataGridFilter from './asset-status-data-grid-filter'
import { useProcessAssetRowUpdateCallback } from './assets-data-grid-hooks'
import {
  ASSET_ACTION_REQUIRED_BY_OPTIONS,
  ASSET_OWNERSHIP_OPTIONS,
  ASSET_PIPELINE_OPTIONS,
  getAssetActionRequiredByChipInfo,
  getAssetPipelineChipInfo,
  getAssetStatusChipProps,
  stringAvatarProps,
} from './assets-data-grid-utils'

export interface AssetsDataGridProps
  extends Pick<SwrDataGridProps<'data.listAssets', AssetListItem>, 'filter' | 'sort'> {
  actions?: ReactNode[]
}

const StyledOverflowContainer = styled(OverflowContainer)({
  [`& .${overflowContainerClasses.child}`]: {
    alignItems: 'center',
  },
})

export default function AssetsDataGrid({
  actions,
  filter,
  sort = [{ field: 'unpublishedAt', direction: 'asc' }],
}: AssetsDataGridProps) {
  const processAssetRowUpdateCallback = useProcessAssetRowUpdateCallback()

  return (
    <SwrDataGrid<'data.listAssets', AssetListItem>
      actions={actions}
      bulkActions={
        <SwrDataGridBulkContainer
          itemNamePlural={'assets'}
          itemNameSingular={'asset'}>
          <AssetBulkDataGrid />
        </SwrDataGridBulkContainer>
      }
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          minWidth: 225,
          // We do not want to show any filters for name
          renderHeaderFilter: () => null,
          renderCell: ({ row }) => (
            <CmsLink
              id={row.id}
              label={row.name}
              type={'asset'}
            />
          ),
        },
        {
          field: 'actionRequiredBy',
          headerName: 'Action Required',
          type: 'options',
          minWidth: 150,
          editable: true,
          options: ASSET_ACTION_REQUIRED_BY_OPTIONS,
          renderCell: ({ value }: GridRenderCellParams) => {
            const { label, color } = getAssetActionRequiredByChipInfo(value)

            return (
              <Chip
                color={color}
                label={label}
                size={'small'}
              />
            )
          },
        },
        {
          field: 'priority',
          headerName: 'Priority',
          type: 'boolean',
          minWidth: 100,
          renderCell: ({ value }) => (
            <Typography color={value ? 'error' : 'action.disabled'}>
              <WarningIcon color={'inherit'} />
            </Typography>
          ),
          editable: true,
        },
        {
          field: 'dueDate',
          headerName: 'Due date',
          type: 'date',
          minWidth: 125,
          editable: true,
        },
        {
          field: 'projectIds',
          headerName: 'Project',
          editable: true,
          minWidth: 200,
          type: 'listMethod',
          method: 'cms.listProjects',
          valueProp: 'id',
          labelProp: 'name',
          allowFilterMultiple: true,
          renderEditCell: renderAssetProjectEditCell,
          renderCell: ({ row }: GridRenderCellParams) => (
            <StyledOverflowContainer
              lineHeight={48}
              maxLines={1}
              spacing={0.5}
              disableObserver>
              {row.projects.map((project: AssetProject) => (
                <Chip
                  key={project.id}
                  label={project.name}
                  size={'small'}
                  sx={(theme) => ({
                    backgroundColor: project.color,
                    color: theme.palette.getContrastText(project.color),
                  })}
                />
              ))}
            </StyledOverflowContainer>
          ),
        },
        {
          field: 'pipeline',
          headerName: 'Pipeline',
          type: 'options',
          minWidth: 150,
          editable: true,
          options: ASSET_PIPELINE_OPTIONS,
          allowFilterMultiple: true,
          renderCell: ({ value }: GridRenderCellParams) => {
            if (!value) {
              return '-'
            }

            const { label, color } = getAssetPipelineChipInfo(value)
            return (
              <Chip
                label={label}
                size={'small'}
                sx={(theme) => ({
                  backgroundColor: color,
                  color: theme.palette.getContrastText(color),
                })}
              />
            )
          },
        },
        {
          field: 'assigneeIds',
          headerName: 'Assignee',
          editable: true,
          minWidth: 200,
          type: 'listMethod',
          method: 'customer.listUsers',
          valueProp: 'id',
          allowFilterMultiple: true,
          getOptionLabel: (user) => {
            if (!user) {
              return ''
            }

            return `${user.firstName} ${user.lastName}`
          },
          defaultMethodFilter: [
            listFilter('status', '=', 'active'),
            listFilter('customerId', '=', 1),
          ],
          renderEditCell: renderAssetAssigneeEditCell,
          renderCell: ({ row }: GridRenderCellParams) => (
            <StyledOverflowContainer
              lineHeight={48}
              maxLines={1}
              spacing={0.5}
              disableObserver>
              {row.assignees.map((assignee: AssetAssignee) => (
                <Tooltip
                  key={assignee.id}
                  title={`${assignee.firstName} ${assignee.lastName}`}>
                  <Avatar
                    variant={'rounded'}
                    {...stringAvatarProps(`${assignee.firstName} ${assignee.lastName}`)}
                  />
                </Tooltip>
              ))}
            </StyledOverflowContainer>
          ),
        },
        {
          field: 'profileType',
          headerName: 'Type',
          type: 'singleSelect',
          minWidth: 100,
          valueOptions: [
            AssetProfileType.Full,
            AssetProfileType.Minimal,
            AssetProfileType.Limited,
            AssetProfileType.Automated,
          ],
          valueFormatter: (value: string) => (value ? capitalize(value) : '-'),
        },
        {
          field: 'live',
          headerName: 'Status',
          width: 125,
          renderCell: ({ row }: GridRenderCellParams<AssetListItem>) => (
            <Chip
              {...getAssetStatusChipProps(row)}
              size={'small'}
            />
          ),
          sortable: false,
          renderHeaderFilter: AssetStatusDataGridFilter,
        },
        {
          field: 'region',
          headerName: 'Region',
          width: 200,
          type: 'listMethod',
          method: 'data.listRegions',
          labelProp: 'title',
          valueProp: 'name',
          allowFilterMultiple: true,
          renderCell: ({ value }: GridRenderCellParams) => (
            <>
              <Flag code={value} />
              &nbsp;
              {value}
            </>
          ),
        },
        {
          field: 'updateReason',
          headerName: 'Update reason',
          width: 200,
          type: 'chip-options',
          editable: true,
          options: ASSET_UPDATE_REASON_OPTIONS,
          renderCell: ({ row }: GridRenderCellParams<AssetListItem>) => {
            const option = getOption<AssetUpdateReason | null, AssetUpdateReasonOption>(
              ASSET_UPDATE_REASON_OPTIONS,
              row.updateReason
            )

            if (!option) {
              return '-'
            }

            return (
              <Chip
                label={option.label}
                size={'small'}
                sx={(theme) => ({
                  backgroundColor: option.color,
                  color: theme.palette.getContrastText(option.color),
                })}
              />
            )
          },
          minWidth: 150,
        },
        {
          field: 'requestUpdateFromDate',
          headerName: 'Request update from',
          width: 200,
          type: 'date',
          minWidth: 150,
        },
        {
          field: 'ownership',
          headerName: 'Ownership',
          type: 'options',
          options: ASSET_OWNERSHIP_OPTIONS,
          minWidth: 150,
        },
        {
          field: 'lastNoteCreatedAt',
          headerName: 'Last note',
          type: 'dateTime',
          minWidth: 150,
        },
        {
          field: 'unpublishedAt',
          headerName: 'Unpublished',
          type: 'date',
          minWidth: 150,
        },
        {
          field: 'lastFullPublishAt',
          headerName: 'Last full publish',
          type: 'date',
          minWidth: 150,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated',
          type: 'date',
          minWidth: 150,
        },
        {
          field: 'actions',
          type: 'actions',
          getActions: AssetActions,
        },
        /**
         * Hidden columns, needed otherwise the filters don't work
         */
        {
          field: 'profileLive',
        },
        {
          field: 'profileInConversion',
        },
        {
          field: 'assignees',
          editable: true,
        },
        {
          field: 'projects',
          editable: true,
        },
      ]}
      columnVisibilityModel={{
        profileLive: false,
        profileInConversion: false,
        assignees: false,
        projects: false,
      }}
      editMode={'row'}
      filter={filter}
      label={'Companies'}
      method={'data.listAssets'}
      pinnedColumns={{
        left: ['name'],
      }}
      processRowUpdate={processAssetRowUpdateCallback}
      // Wrap each row with an InputForm, so we can allow for inline editing of the asset
      renderRow={({ rowId }: GridRowProps, children: ReactElement) => (
        <InputForm
          archiveMethod={'data.archiveAsset'}
          deleteMethod={'data.deleteAsset'}
          name={'company'}
          publishMethod={'data.publishAssetProfile'}
          recordId={`${rowId}`}
          unArchiveMethod={'data.unarchiveAsset'}
          unPublishMethod={'data.unpublishAssetProfile'}
          validateMethod={'data.validateAssetProfile'}>
          {children}
        </InputForm>
      )}
      sort={sort}
      checkboxSelection
      headerFilters
      useFullHeight
    />
  )
}
