import { CurrencyListItem } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import { useCurrencies } from './api-request-hooks'

/**
 * Returns a callback function to fetch a currency list item by name.
 * Returns undefined if the currency could not be found.
 */
export function useFindCurrencyCallback(): (
  currencyName: string | null | undefined
) => CurrencyListItem | undefined {
  const swrCurrencies = useCurrencies({
    limit: 100,
  })

  return useCallback(
    (currencyName: string | null | undefined) => {
      if (!currencyName || !swrCurrencies?.data) {
        return undefined
      }

      return swrCurrencies.data.items.find(
        ({ name }) => name.toLowerCase() === currencyName.toLowerCase()
      )
    },
    [swrCurrencies?.data]
  )
}
