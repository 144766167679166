import { alpha, styled } from '@mui/material/styles'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, memo, ReactNode } from 'react'

import Icon from './Icon'

type Size = 'small' | 'medium' | 'extra-large'

export type IconColor =
  | 'tint'
  | 'tint-diap'
  | 'red'
  | 'yellow'
  | 'yellow-diap'
  | 'green'
  | 'grey'
  | 'lighten'
  | 'darken'
  | 'textSecondary'
  | 'textSecondary-diap'

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => !['size', 'color'].includes(propName as string),
})<{ size?: Size; color?: IconColor }>(({ theme, size, color }) => ({
  width: 32,
  height: 32,
  fontSize: '18px',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  borderRadius: '16px',
  overflow: 'hidden',

  '&:focus': {
    outline: 0,
    boxShadow: `0 0 5px 0 rgba(${theme.palette.primary.main}, 0.5)`,
  },

  ...(size === 'small' && {
    width: 16,
    height: 16,
    fontSize: '10px',
    borderRadius: '8px',
  }),
  ...(size === 'medium' && {
    width: 20,
    height: 20,
    fontSize: '12px',
    borderRadius: '10px',
  }),
  ...(size === 'extra-large' && {
    width: 48,
    height: 48,
    fontSize: '16px',
    borderRadius: '50%',
  }),

  ...(color === 'tint' && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),
  ...(color === 'tint-diap' && {
    color: theme.palette.primary.main,
  }),
  ...(color === 'red' && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  }),
  ...(color === 'yellow' && {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  }),
  ...(color === 'yellow-diap' && {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.warning.main,
  }),
  ...(color === 'green' && {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    color: theme.palette.success.main,
  }),
  ...(color === 'grey' && {
    backgroundColor: theme.palette.divider,
    color: theme.palette.text.primary,
  }),
  ...(color === 'lighten' && {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    color: theme.palette.common.white,
  }),
  ...(color === 'darken' && {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  }),
  ...(color === 'textSecondary' && {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
  }),
  ...(color === 'textSecondary-diap' && {
    color: theme.palette.text.secondary,
  }),
}))

interface IconInCircleProps
  extends Pick<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'className' | 'style' | 'onClick'
  > {
  size?: Size
  icon?: ReactNode
  colorStyle?: IconColor
  direction?: string
}

const IconInCircle = forwardRef<HTMLDivElement, IconInCircleProps>(function IconInCircle(
  { colorStyle, size, icon, direction, ...rest },
  ref
) {
  const { style, ...restProps } = rest
  return (
    <StyledRoot
      ref={ref}
      color={colorStyle}
      size={size}
      style={style}
      {...restProps}>
      <Icon
        direction={direction}
        src={icon}
      />
    </StyledRoot>
  )
})

export default memo(IconInCircle)
