import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, memo } from 'react'

import { withClassNames } from '../../HigherOrder/withClassNames'
import styles from './KeyboardInput.module.scss'

export const KEYBOARD_INPUT_READONLY = 'isReadonly'

export interface KeyboardInputProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label?: string
  selected?: boolean
  percentage?: boolean
  styleName?: string
}

const KeyboardInput: FunctionComponent<KeyboardInputProps> = ({
  label,
  selected,
  percentage,
  ...rest
}) => <div {...rest} />

export default memo(withClassNames(KeyboardInput, styles)) as typeof KeyboardInput
