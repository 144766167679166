import { useFileInfo } from '@gain/api/cms/hooks'
import { FileStatus } from '@gain/rpc/cms-model'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'

import PillButtonSecondary from '../../Components/Buttons/pill-button-secondary'
import Icon, { ICON_DOCUMENT } from '../../Components/Icons/Icon'
import { FlexContainer } from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_ROUNDBUTTON } from '../../Components/Tabular/FlexTable/FlexItem'
import DocumentIcon, {
  DOCUMENT_ICON_STYLENAME_ADD,
  DOCUMENT_ICON_STYLENAME_IMAGE,
} from '../../Components/Visual/DocumentIcon'
import FileStatusIcon from './file-status-icon'
import FilenameLabel from './FilenameLabel'

export const FILE_ACTION_DISPLAY = 'display'
export const FILE_ACTION_UPLOAD = 'upload'
export const FILE_ACTION_REPLACE = 'replace'
export const FILE_ACTION_TYPE_IMAGE = 'image'

const FILE_STATUS_POLL_INTERVAL = 5000

const FileGroupContainer = styled(FlexContainer)({
  alignItems: 'center',
})

const SuffixButtonFlexItem = styled(FlexItem)({
  flex: '0 0 auto',
})

interface FileActionsGroupProps {
  fileId?: number | null
  action?: string
  fileType?: string
  filename?: string | null
  imageUrl?: string
  newFilename?: boolean
  disableIcon?: boolean
  name?: string | null
}

export default function FileActionsGroup(props: FileActionsGroupProps) {
  const {
    action = FILE_ACTION_DISPLAY,
    fileType,
    imageUrl,
    fileId,
    filename,
    newFilename,
    name = 'file',
    disableIcon,
  } = props

  // Conditionally fetch file info if there is a file id
  const fileInfo = useFileInfo(fileId ? fileId : null, {
    // If the file is not scanned yet, poll the status of the file
    refreshInterval: (data) => {
      const shouldPollFileStatus =
        data?.status === FileStatus.Unscanned ||
        data?.status === FileStatus.UnscannedFailed ||
        data?.status === FileStatus.UnscannedPriority

      return shouldPollFileStatus ? FILE_STATUS_POLL_INTERVAL : 0
    },
  })

  const replaceFile = filename != null || imageUrl
  const iconStyleName = classNames(
    replaceFile ? '' : DOCUMENT_ICON_STYLENAME_ADD,
    fileType === FILE_ACTION_TYPE_IMAGE ? DOCUMENT_ICON_STYLENAME_IMAGE : ''
  )

  // Determine image specific styles
  const statusIconStyle = fileType === FILE_ACTION_TYPE_IMAGE ? { left: 110 } : undefined
  const fileIconStyle = { minWidth: fileType === FILE_ACTION_TYPE_IMAGE ? 120 : 40 }

  return (
    <FileGroupContainer>
      {/* File icon that prefixes the filename */}
      {!disableIcon && (
        <FlexItem
          style={fileIconStyle}
          styleName={FLEXITEM_ROUNDBUTTON}>
          {/* Icon to indicate virus scanning status */}
          {fileId != null && (
            <FileStatusIcon
              fileStatus={fileInfo.data?.status}
              style={statusIconStyle}
            />
          )}

          {/* File icon */}
          <DocumentIcon styleName={iconStyleName}>
            {replaceFile &&
              (imageUrl ? (
                <img
                  alt={''}
                  src={imageUrl}
                />
              ) : (
                <Icon src={ICON_DOCUMENT} />
              ))}
          </DocumentIcon>
        </FlexItem>
      )}

      {/* Label for the full filename */}
      {filename != null && fileId != null && (
        <FlexItem>
          <FilenameLabel
            fileId={fileId}
            filename={filename}
            imageUrl={imageUrl}
            newFilename={newFilename}
          />
        </FlexItem>
      )}

      {/* Suffix button, like replace or add file */}
      {action !== FILE_ACTION_DISPLAY && (
        <SuffixButtonFlexItem>
          <PillButtonSecondary>
            {replaceFile ? `Replace ${name}` : `Add ${name}`}
          </PillButtonSecondary>
        </SuffixButtonFlexItem>
      )}
    </FileGroupContainer>
  )
}
