import { CreateCreditParams, Credit } from '@gain/rpc/cms-model'
import { OmitReadonly } from '@gain/utils/typescript'
import { isBefore } from 'date-fns/isBefore'
import { startOfMonth } from 'date-fns/startOfMonth'
import * as yup from 'yup'

import { yupNumber } from '../../../common/input-fields/input-field-yup'

export const createCreditSchema: Record<keyof CreateCreditParams, yup.AnySchema> = {
  assetId: yupNumber().nullable(),
  type: yup.string().required(),
  issuedAt: yup.date().nullable(),
  maturedAt: yup.date().nullable(),
  reportedAt: yup.date().required(),
  subtype: yup.string().nullable(),
}

export const noteSchema = yup.object({
  text: yup.string().min(1).trim().required(),
})

export const sourceSchema = yup.object({
  title: yup.string().min(1).trim().nullable().required(),
  source: yup.string().min(1).trim().nullable(),
  publishedAt: yup
    .date()
    .test('max', 'Publication date cannot be in the future', (value) => {
      if (!value) {
        return true
      }

      return isBefore(value, new Date())
    })
    .nullable()
    .transform((value) => {
      if (!value) {
        return value
      }

      return startOfMonth(value)
    }),
  url: yup.string().url().trim().nullable(),
})

// isCurrentlyOutstanding must be set from deal or asset profile
type UpdateCreditSchema = Omit<
  Record<keyof OmitReadonly<Credit>, yup.AnySchema>,
  'isCurrentlyOutstanding'
>

export const updateCreditSchema: UpdateCreditSchema = {
  ...createCreditSchema,
  assetName: yup.string().nullable(),
  dealId: yupNumber().nullable(),
  issuedAtIsEstimate: yup.boolean(),
  debtQuantum: yupNumber().min(0).nullable(),
  debtQuantumCurrency: yup.string().nullable(),
  cost: yupNumber().nullable(),
  costCurrency: yup.string().nullable(),
  fairValue: yupNumber().nullable(),
  fairValueCurrency: yup.string().nullable(),
  referenceRate: yup.string().nullable(),
  couponBps: yupNumber().nullable(),
  totalInterestPct: yupNumber().min(0).nullable(),
  paymentInKindPct: yupNumber().min(0).nullable(),
  floorPct: yupNumber().nullable(),
  lenders: yup
    .array()
    .of(
      yup.object({
        lenderId: yupNumber().nullable(),
      })
    )
    .required(),
  notes: yup.array().of(noteSchema).nullable(),
  sources: yup.array().of(sourceSchema).nullable(),
}
