import { useTagListItem } from '@gain/api/cms/hooks'
import { formatDate } from '@gain/utils/date'
import { useDialogState } from '@gain/utils/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useRef } from 'react'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import {
  InputFieldGroup,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'

export interface RouteTagProps {
  onCrudAction: () => void
}

export default function RouteTag({ onCrudAction }: RouteTagProps) {
  const [showDeleteAlert, openDeleteAlert, closeDeleteAlert] = useDialogState()

  const routeMatch = useRouteMatch<{ tagId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)

  const swrTag = useTagListItem(
    routeMatch.params.tagId !== 'new' ? parseInt(routeMatch.params.tagId) : null
  )

  const inputFormAPI = useInputFormAPI({
    createMethod: 'data.getOrCreateTagByName',
    updateMethod: 'data.updateTag',
    deleteMethod: 'data.deleteTag',
    values: swrTag.data || {},
    validationSchema: {
      name: yup.string().required(),
    },
  })

  const handleUpdateOrCreateTag = inputFormAPI.form.handleSubmit(async (values) => {
    let success: boolean

    if (routeMatch.params.tagId === 'new') {
      success = await inputFormAPI.create({ tagName: values.name?.trim() as string })
    } else {
      success = await inputFormAPI.patch(parseInt(routeMatch.params.tagId), {
        name: values.name?.trim(),
      })
    }

    if (success) {
      onCrudAction()
      drawerRef.current?.close(true)
    }
  })

  const handleDrawerClose = useCallback(() => {
    if (inputFormAPI.form.formState.isDirty) {
      return window.confirm(
        'Are you sure you want to close this window. Your changes won’t be saved.'
      )
    }

    return true
  }, [inputFormAPI.form.formState.isDirty])

  const handleDeleteTag = useCallback(async () => {
    closeDeleteAlert()

    await inputFormAPI.delete(parseInt(routeMatch.params.tagId), undefined, () => {
      onCrudAction()
      drawerRef.current?.close(true)
    })
  }, [closeDeleteAlert, inputFormAPI, onCrudAction, routeMatch.params.tagId])

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <LoadingButton
          disabled={inputFormAPI.busy}
          loading={inputFormAPI.saving}
          onClick={handleUpdateOrCreateTag}
          variant={'contained'}>
          Save
        </LoadingButton>
      }
      footerAction={
        routeMatch.params.tagId !== 'new' && (
          <LoadingButton
            color={'error'}
            disabled={inputFormAPI.busy}
            loading={inputFormAPI.deleting}
            onClick={openDeleteAlert}
            variant={'contained'}>
            Delete
          </LoadingButton>
        )
      }
      onClose={handleDrawerClose}
      title={routeMatch.params.tagId !== 'new' ? 'Edit tag' : 'New tag'}>
      <InputFormProvider form={inputFormAPI.form}>
        <InputFieldText
          label={'Name'}
          name={'name'}
          required
        />

        {routeMatch.params.tagId !== 'new' && swrTag.data && (
          <InputFieldGroup
            alignItems={'flex-start'}
            direction={'column'}>
            <Typography variant={'subtitle1'}>Information</Typography>

            <TextField
              label={'Companies'}
              name={'assetCount'}
              value={swrTag.data.assetCount || '0'}
              variant={'outlined'}
              disabled
              fullWidth
            />

            <InputFieldGroup>
              <TextField
                label={'Updated at'}
                name={'updatedAt'}
                value={formatDate(swrTag.data.updatedAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />

              <TextField
                label={'Created at'}
                name={'createdAt'}
                value={formatDate(swrTag.data.createdAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </InputFieldGroup>
          </InputFieldGroup>
        )}
      </InputFormProvider>

      <Dialog
        onClose={closeDeleteAlert}
        open={showDeleteAlert}>
        <DialogTitle>Delete tag</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this tag? This tag is linked to{' '}
            {swrTag.data?.assetCount} profiles and could be used in saved filters. This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'error'}
            onClick={handleDeleteTag}
            variant={'contained'}>
            Delete tag
          </Button>
        </DialogActions>
      </Dialog>
    </RightDrawer>
  )
}
