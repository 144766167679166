import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { MouseEvent, useCallback } from 'react'

import InputError from '../../../Components/FormElements/Error/InputError'
import Icon, { ICON_UNLINK } from '../../../Components/Icons/Icon'
import NameWithLogo from '../../../Components/NameWithLogo/NameWithLogo'
import Pill, {
  PILL_COLORSTYLE_BLUE,
  PILL_COLORSTYLE_GREEN,
  PILL_COLORSTYLE_RED,
  PILL_COLORSTYLE_YELLOW,
} from '../../../Components/Pill/Pill'
import StickyTableHeader from '../../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_FIXED,
  CELL_TYPE_MEDIUM,
  CELL_TYPE_PILL,
  CELL_TYPE_PROFILETYPE,
  CELL_TYPE_REGION,
  CELL_TYPE_XLARGE,
} from '../../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../../Components/Tabular/TabularRow/TabularRow'
import Text, { SIZE_CAPTION_1 } from '../../../Components/Text/Text'
import { TabularCellSortLink } from '../../../Compositions/Link/TabularCellSortLink'
import { ErrorContainer } from '../../../Compositions/UpdateForm/ErrorContainer'
import { MethodContextProvider } from '../../../Context/method-context.provider'
import ownership from '../../../json/ownership.json'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../../../routes/route-asset/route-asset-path'
import { COLOR_RED, COLORNAME_TEXT_PRIMARY, COLORNAME_TEXT_SECONDARY } from '../../../util/colors'
import { ASSET_METHODS } from '../../../util/methods'
import { pathGenerate } from '../../../util/pathGenerate'
import AssetUnpublishedAtCell from './asset-unpublished-at-cell.component'
import { ListContainer } from './ListContainer'

export interface AssetsListProps {
  path?: string
  // json path to assets array
  jsonPath?: string
  title?: string
  onUnlinkAsset?: (id: number) => void
  disableUnlinkAutomatedAssets?: boolean
}

export default function AssetsList({
  path,
  jsonPath,
  onUnlinkAsset,
  disableUnlinkAutomatedAssets,
  ...props
}: AssetsListProps) {
  const title = props.title || 'No linked companies'

  const getAssetStatusPillColor = useCallback((asset: AssetListItem) => {
    if (asset.live && asset.profileLive) {
      return PILL_COLORSTYLE_GREEN
    } else if (asset.live && !asset.profileLive && !asset.profileInConversion) {
      return PILL_COLORSTYLE_YELLOW
    } else if (asset.live && !asset.profileLive && asset.profileInConversion) {
      return PILL_COLORSTYLE_BLUE
    }

    return PILL_COLORSTYLE_RED
  }, [])

  const getAssetStatusPillText = useCallback((asset: AssetListItem) => {
    if (asset.live && asset.profileLive) {
      return 'Online'
    } else if (asset.live && !asset.profileLive && !asset.profileInConversion) {
      return 'Profile Offline'
    } else if (asset.live && !asset.profileLive && asset.profileInConversion) {
      return 'Conversion'
    }

    return 'Offline'
  }, [])

  const canUnlinkAsset = useCallback(
    (asset: AssetListItem) => {
      if (!disableUnlinkAutomatedAssets) {
        return true
      }

      return asset.profileType !== AssetProfileType.Automated
    },
    [disableUnlinkAutomatedAssets]
  )

  return (
    <>
      <StickyTableHeader>
        <Tabular styleName={TABULAR_HEADER}>
          <TabularRow styleName={ROWTYPE_HEAD}>
            <TabularCellSortLink
              path={path}
              sort={'-name'}
              styleName={CELL_TYPE_XLARGE}>
              Name
            </TabularCellSortLink>

            <TabularCellSortLink
              path={path}
              sort={'-region'}
              styleName={CELL_TYPE_REGION}>
              HQ
            </TabularCellSortLink>
            <TabularCellSortLink
              path={path}
              sort={'-profileType'}
              styleName={`${CELL_TYPE_FIXED} ${CELL_TYPE_PROFILETYPE}`}>
              Type
            </TabularCellSortLink>
            <TabularCellSortLink
              path={path}
              sort={'-ownership'}
              styleName={CELL_TYPE_MEDIUM}>
              Ownership
            </TabularCellSortLink>
            <TabularCellSortLink
              path={path}
              sort={'unpublishedAt'}
              styleName={CELL_TYPE_MEDIUM}>
              Unpublished
            </TabularCellSortLink>
            <TabularCellSortLink
              path={path}
              sort={'-profileLive_-live'}
              styleName={CELL_TYPE_PILL}>
              Status
            </TabularCellSortLink>
            <TabularCell styleName={CELL_TYPE_ACTIONS} />
          </TabularRow>
        </Tabular>
      </StickyTableHeader>
      <Tabular>
        <ListContainer title={title}>
          {(data: AssetListItem[]) =>
            data.map((item, index) => (
              <MethodContextProvider
                key={item.id}
                data={item}
                methods={ASSET_METHODS}>
                <TabularRow styleName={ROWTYPE_HOVER}>
                  {jsonPath && (
                    <ErrorContainer path={`${jsonPath}[${index}]`}>
                      {(onClick, hasErrors) => (
                        <InputError
                          hasErrors={hasErrors}
                          onClick={onClick}
                          style={{ marginTop: 0 }}
                        />
                      )}
                    </ErrorContainer>
                  )}
                  <TabularCell styleName={CELL_TYPE_CLICK}>
                    <TabularRow
                      to={pathGenerate(ROUTE_ASSET_KEY_FACTS_PATH, {
                        id: item.id,
                      })}>
                      <TabularCell
                        colorName={COLORNAME_TEXT_PRIMARY}
                        size={SIZE_CAPTION_1}
                        styleName={CELL_TYPE_XLARGE}>
                        <NameWithLogo name={item.name} />
                      </TabularCell>
                      <TabularCell styleName={CELL_TYPE_REGION}>
                        <Flag code={item.region} />
                      </TabularCell>
                      <TabularCell styleName={`${CELL_TYPE_FIXED} ${CELL_TYPE_PROFILETYPE}`}>
                        <Text
                          colorName={COLORNAME_TEXT_SECONDARY}
                          style={{
                            textTransform: 'capitalize',
                          }}>
                          {item.profileType}
                        </Text>
                      </TabularCell>
                      <TabularCell
                        colorName={COLORNAME_TEXT_PRIMARY}
                        styleName={CELL_TYPE_MEDIUM}>
                        {item.ownership ? ownership[item.ownership] : '-'}
                      </TabularCell>
                      <TabularCell
                        colorName={COLORNAME_TEXT_PRIMARY}
                        styleName={CELL_TYPE_MEDIUM}>
                        <AssetUnpublishedAtCell unpublishedAt={item.unpublishedAt} />
                      </TabularCell>
                      <TabularCell styleName={CELL_TYPE_PILL}>
                        <Pill colorStyle={getAssetStatusPillColor(item)}>
                          {getAssetStatusPillText(item)}
                        </Pill>
                      </TabularCell>
                    </TabularRow>
                  </TabularCell>
                  <TabularCell styleName={CELL_TYPE_ACTIONS}>
                    {onUnlinkAsset && canUnlinkAsset(item) && (
                      <Icon
                        iconColor={COLOR_RED}
                        onClick={(event: MouseEvent) => {
                          event.stopPropagation()
                          event.preventDefault()
                          onUnlinkAsset(item.id)
                        }}
                        src={ICON_UNLINK}
                        style={{ fontSize: 24 }}
                        title={'Unlink asset'}
                      />
                    )}
                  </TabularCell>
                </TabularRow>
              </MethodContextProvider>
            ))
          }
        </ListContainer>
      </Tabular>
    </>
  )
}
