import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

import ConsistencyGuideButton from './consistency-guide-button'

const StyledCard = styled(Card, {
  shouldForwardProp: (propName) => propName !== 'insideLegacy',
})<ItemPageBlockProps>(({ theme, insideLegacy }) => ({
  marginBottom: insideLegacy ? theme.spacing(1) : 0,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 2, 1, 2),
  gap: theme.spacing(2),
}))

export interface ItemPageBlockProps {
  label?: string
  children: ReactNode
  insideLegacy?: boolean
  consistencyGuideName?: string
}

export function ItemPageBlock({
  insideLegacy,
  label,
  children,
  consistencyGuideName,
}: ItemPageBlockProps) {
  return (
    <StyledCard insideLegacy={insideLegacy}>
      {label && (
        <CardHeader
          title={
            <Stack
              alignItems={'center'}
              flexDirection={'row'}>
              <Typography variant={'inherit'}>{label}</Typography>
              <ConsistencyGuideButton
                consistencyGuideName={consistencyGuideName}
                label={label}
              />
            </Stack>
          }
        />
      )}
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  )
}
