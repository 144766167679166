import { Asset, AssetListItem } from '@gain/rpc/cms-model'

import { isAsset } from '../../route-asset-utils'

export enum NextUpdateDateType {
  Manual = 'manual',
  Automated = 'automated',
  Existing = 'existing',
}

export function getAssetHQ(asset: Asset | AssetListItem) {
  if (isAsset(asset)) {
    return asset.generalInfo?.headquarters
  }

  return asset.region
}
