import { NOTE_EDITOR_FILE_TYPE } from './blocks/file'
import { blockEditorSchema } from './note-editor-schema'

export function storeEditorStateInStorage(
  editor: typeof blockEditorSchema.BlockNoteEditor,
  key: string
): void {
  const blocks = filterEditorBlocks(editor)

  // If the editor is empty, remove the item from storage (happens when the note is created).
  // We use 1 here as the editor always adds an empty block below the last block of the user, so when the editor
  // only has one left, it's safe to assume the editor has been cleared.
  if (blocks.length <= 1) {
    localStorage.removeItem(key)
    return
  }

  localStorage.setItem(key, JSON.stringify(blocks))
}

export function restoreEditorStateFromStorage(
  editor: typeof blockEditorSchema.BlockNoteEditor,
  key?: string
): void {
  // Replace all blocks with the blocks from storage
  // or remove all blocks if there is nothing in storage
  editor.replaceBlocks(editor.document, getEditorStateFromStorage(key) || [])
}

export function getEditorStateFromStorage(
  key?: string
): (typeof blockEditorSchema.Block)[] | undefined {
  if (!key) {
    return undefined
  }

  const state = localStorage.getItem(key)

  if (state) {
    return JSON.parse(state)
  }

  return undefined
}

export function filterEditorBlocks(
  editor: typeof blockEditorSchema.BlockNoteEditor
): (typeof blockEditorSchema.Block)[] {
  return editor.document.filter((block) => {
    // Make sure that the file blocks actually did upload a file
    const validBlock = block.type !== NOTE_EDITOR_FILE_TYPE || Boolean(block.props.fileId)

    return validBlock
  })
}
