import { SlotHome, SlotName } from '@gain/components/slot'
import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

import { NOTES_DRAWER_WIDTH, notesDrawerClasses } from '../../features/notes-drawer'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  '& > div:first-of-type': {
    flex: 1,
    maxWidth: '100vw',
    willChange: 'max-width',

    [`.${notesDrawerClasses.drawerOpen} &`]: {
      maxWidth: `calc(100vw - ${NOTES_DRAWER_WIDTH})`,
    },
  },
})

export function ItemPageRoutesContainer({ children }: PropsWithChildren) {
  return (
    <StyledContainer>
      {children}

      <SlotHome slotName={SlotName.Drawer} />
    </StyledContainer>
  )
}
