import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { useState } from 'react'

import { isChipOptionsColumn } from '../chip-options/swr-data-grid-chip-options-utils'
import { isOptionsColumn } from './swr-data-grid-options-utils'

export function renderOptionsEditCell(params: GridRenderEditCellParams) {
  return <OptionsEditCell {...params} />
}

function OptionsEditCell({ id, field, colDef, api, value }: GridRenderEditCellParams) {
  const [selectValue, setSelectValue] = useState<string>(value)

  const handleChange = (event: SelectChangeEvent) => {
    api.setEditCellValue({ id, field, value: event.target.value || null })
    setSelectValue(event.target.value)
  }

  if (!isOptionsColumn(colDef) && !isChipOptionsColumn(colDef)) {
    return null
  }

  return (
    <FormControl
      sx={{ m: 0.5 }}
      fullWidth>
      <Select
        MenuProps={{
          slotProps: {
            paper: {
              // 36 = item height * 8.5 (8.5 items visible)
              // 8 = padding top
              style: { maxHeight: 36 * 8.5 + 8 },
            },
          },
        }}
        onChange={handleChange}
        size={'small'}
        value={selectValue}
        autoWidth>
        {colDef.clearable !== false && (
          <MenuItem value={''}>
            <em>Clear</em>
          </MenuItem>
        )}
        {colDef.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
