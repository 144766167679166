import { stringToColor } from '@gain/utils/common'
import { getUserInitials } from '@gain/utils/user'
import { SxProps, Theme } from '@mui/material/styles'

export function stringNameToAvatarProps(name: string): { children: string; sx: SxProps<Theme> } {
  return {
    sx: (theme: Theme) => {
      const bgcolor = stringToColor(name)

      return {
        bgcolor,
        width: 20,
        height: 20,
        fontSize: 12,
        color: theme.palette.getContrastText(bgcolor),
      }
    },
    children: getUserInitials(name),
  }
}
