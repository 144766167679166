import { CREDIT_TYPE_OPTIONS } from '@gain/rpc/shared-model'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { generatePath, useHistory } from 'react-router'

import {
  InputFieldAutoComplete,
  InputFieldDate,
  InputFieldGroup,
  InputFieldSelect,
  InputFormProvider,
  useInputFormAPI,
} from '../../common/input-fields'
import InputFieldCreditSubtype from '../../common/input-fields/input-field-credit-subtype'
import { createCreditSchema } from './route-credit/credit-schema'
import { CREDIT_GENERAL_PAGE_PATH } from './route-credit/route-credit-path'

export interface CreateLenderDialogProps {
  show: boolean
  onClose: () => void
}

export default function CreateCreditDialog({ show, onClose }: CreateLenderDialogProps) {
  const inputFormAPI = useInputFormAPI({
    defaultValues: {
      linkedInExternalId: '',
    },
    validationSchema: createCreditSchema,
    createMethod: 'data.createCredit',
  })
  const history = useHistory()

  const handleSubmit = inputFormAPI.form.handleSubmit(async (values) => {
    await inputFormAPI.create(
      {
        assetId: values.assetId,
        type: values.type,
        subtype: values.subtype,
        issuedAt: values.issuedAt,
        maturedAt: values.maturedAt,
        reportedAt: values.reportedAt,
      },
      (credit) => {
        history.push(
          generatePath(CREDIT_GENERAL_PAGE_PATH, {
            id: credit.id,
          })
        )

        onClose()
      }
    )
  })

  // Reset subtype when the type is changed
  const handleTypeChange = async () => {
    inputFormAPI.form.setValue('subtype', null)
  }

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={show}
      fullWidth>
      <DialogTitle>Create a new credit</DialogTitle>
      <DialogContent>
        <InputFormProvider form={inputFormAPI.form}>
          <Stack
            gap={2}
            mt={0.5}>
            <InputFieldAutoComplete
              label={'Target company'}
              labelProp={'name'}
              method={'data.listAssets'}
              name={'assetId'}
              valueProp={'id'}
            />

            <InputFieldGroup>
              <InputFieldSelect
                label={'Type'}
                name={'type'}
                onChange={handleTypeChange}
                options={CREDIT_TYPE_OPTIONS}
                required
              />

              <InputFieldCreditSubtype
                label={'Subtype'}
                name={'subtype'}
                typeFieldName={'type'}
              />
            </InputFieldGroup>

            <InputFieldGroup>
              <InputFieldDate
                label={'Reported date'}
                name={'reportedAt'}
                required
              />
              <InputFieldDate
                label={'Issuance date'}
                name={'issuedAt'}
              />
              <InputFieldDate
                label={'Maturity date'}
                name={'maturedAt'}
              />
            </InputFieldGroup>
          </Stack>
        </InputFormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inputFormAPI.busy}
          onClick={onClose}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          disabled={inputFormAPI.busy}
          onClick={handleSubmit}
          variant={'contained'}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
