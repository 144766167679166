import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'

import { createItemSpaceStyle } from '../../SpacedItems/SpacedItems'

const StyledRoot = styled('div', {
  shouldForwardProp: (propName) => propName !== 'repeater',
})<{ repeater: boolean }>(({ theme, repeater }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'stretch',
  ...(repeater && {
    transition: 'all 0.2s linear',
    paddingRight: theme.spacing(1),
  }),
}))

export const FLEXCONTAINER_REPEATER = 'styleNameRepeater'

export type FlexContainerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  styleName?: 'styleNameRepeater'
  itemSpace?: string | number
}

export const FlexContainer = memo<FlexContainerProps>(
  ({ styleName, style = undefined, itemSpace = 8, className, ...rest }) => {
    return (
      <StyledRoot
        className={className}
        repeater={styleName === 'styleNameRepeater'}
        style={createItemSpaceStyle(style, itemSpace)}
        {...rest}
      />
    )
  }
)
