import { ROUTE_ADVISORS_PATH } from '../route-advisors'

export enum AdvisorRouteTabs {
  Profile = 'profile',
  DealsAdvised = 'deals-advised',
}

const ADVISOR_PAGE_PATH = `${ROUTE_ADVISORS_PATH}/:id`
export const ADVISOR_TABS_PAGE_PATH = `${ADVISOR_PAGE_PATH}/:tab?`
export const ADVISOR_DEALS_ADVISED_PAGE_PATH = `${ADVISOR_PAGE_PATH}/${AdvisorRouteTabs.DealsAdvised}`
export const ADVISOR_PROFILE_PAGE_PATH = `${ADVISOR_PAGE_PATH}/${AdvisorRouteTabs.Profile}`
