import { isDefined } from '@gain/utils/common'
import { ChangeEvent } from 'react'

export interface InputFieldTransform {
  input: (value: string | number) => string | number
  output: (event: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>
}

export const defaultTransform: InputFieldTransform = {
  // We always return a string as default, this makes sure that values like 0 are still visible inside the text fields
  input: (value) => (isDefined(value) ? `${value}` : ''),
  output: (event) => event,
}
