import { CustomerListItem } from '@gain/rpc/cms-model'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { ReactElement, useRef } from 'react'

import InputForm from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import { CUSTOMER_GENERAL_PATH } from '../route-customer'
import CreateCustomerDialog from './create-customer-dialog'
import CustomerActions from './customer-actions'
import ExportCustomerUsage from './export-customer-usage'
import { NAV_CUSTOMERS } from './route-customers-path'

export default function RouteCustomers() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  return (
    <LayoutDataGrid title={NAV_CUSTOMERS}>
      <Card>
        <SwrDataGrid<'customer.listCustomers', CustomerListItem>
          ref={dataGridRef}
          actions={[
            <ExportCustomerUsage key={'export-usage'} />,

            <CreateCustomerDialog key={'new-customer'} />,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              filterable: false,
              flex: 1,
            },
            {
              field: 'usersActive',
              headerName: 'Active users',
              width: 150,
              filterable: false,
            },
            {
              field: 'usersDeactivated',
              headerName: 'Deactivated users',
              width: 150,
              filterable: false,
            },
            {
              field: 'defaultUserCurrency',
              headerName: 'Default currency',
              width: 150,
              filterable: false,
            },
            {
              field: 'deactivated',
              headerName: 'Status',
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'error' : 'success'}
                  label={value ? 'Deactivated' : 'Activated'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: CustomerActions,
            },
          ]}
          label={NAV_CUSTOMERS}
          method={'customer.listCustomers'}
          path={CUSTOMER_GENERAL_PATH}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              archiveMethod={'customer.deactivateCustomer'}
              deleteMethod={'customer.deleteCustomer'}
              isDisabled={() => row.deactivated}
              recordId={`${rowId}`}
              unArchiveMethod={'customer.reactivateCustomer'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
