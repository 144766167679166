export enum CustomerRouteTabs {
  General = 'general',
  Subscription = 'subscription',
  Users = 'users',
  Security = 'security',
  ApiKeys = 'api-keys',
}

const CUSTOMER_PAGE_PATH = '/customers/:id'
export const ROUTE_CUSTOMER_TABS_PATH = `${CUSTOMER_PAGE_PATH}/:tab?`
export const CUSTOMER_GENERAL_PATH = `${CUSTOMER_PAGE_PATH}/${CustomerRouteTabs.General}`
export const CUSTOMER_SUBSCRIPTION_PATH = `${CUSTOMER_PAGE_PATH}/${CustomerRouteTabs.Subscription}`
export const CUSTOMER_USERS_PATH = `${CUSTOMER_PAGE_PATH}/${CustomerRouteTabs.Users}`
export const CUSTOMER_SECURITY_PATH = `${CUSTOMER_PAGE_PATH}/${CustomerRouteTabs.Security}`
export const CUSTOMER_API_KEYS_PATH = `${CUSTOMER_PAGE_PATH}/${CustomerRouteTabs.ApiKeys}`
