import { ConferenceEdition, ConferenceExhibitorListItem } from '@gain/rpc/cms-model'
import { listFilter } from '@gain/rpc/utils'
import { isNumber } from '@gain/utils/typescript'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import { useCallback, useRef } from 'react'
import { Route, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'

import { useInputFormData } from '../../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../../common/swr-data-grid'
import { ItemPageContent } from '../../../layout/item-page'
import { ADVISOR_PROFILE_PAGE_PATH } from '../../route-advisor'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../../route-asset'
import { CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH } from '../../route-conference-edition'
import { INVESTOR_PROFILE_PATH } from '../../route-investor'
import ConferenceEditionExhibitorRoute from './exhibitor'

export default function ConferenceEditionExhibitorsRoute() {
  const data = useInputFormData<ConferenceEdition>()
  const params = useParams<{ id: string }>()

  const dataGridRef = useRef<SwrDataGridRef>(null)
  const exhibitorsPath = generatePath(CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH, { id: params.id })

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <ItemPageContent variant={'grid'}>
      <Card>
        <SwrDataGrid
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-person'}
              disabled={data?.live}
              href={`${exhibitorsPath}/new`}
              variant={'contained'}>
              Add exhibitor
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              filterable: false,
              minWidth: 125,
              flex: 1,
            },
            {
              field: 'linkedName',
              headerName: 'Linked',
              flex: 1,
              renderCell: ({ row }) => {
                let href: null | string = null
                if (isNumber(row.linkedAssetId)) {
                  href = generatePath(ROUTE_ASSET_KEY_FACTS_PATH, {
                    id: row.linkedAssetId,
                  })
                } else if (isNumber(row.linkedInvestorId)) {
                  href = generatePath(INVESTOR_PROFILE_PATH, {
                    id: row.linkedInvestorId,
                  })
                } else if (isNumber(row.linkedAdvisorId)) {
                  href = generatePath(ADVISOR_PROFILE_PAGE_PATH, {
                    id: row.linkedAdvisorId,
                  })
                }

                if (href) {
                  return <Link href={href}>{row.linkedName}</Link>
                }

                return '-'
              },
            },
          ]}
          filter={[
            listFilter<ConferenceExhibitorListItem>(
              'conferenceEditionId',
              '=',
              parseInt(params.id, 10)
            ),
          ]}
          label={'Exhibitors'}
          method={'data.listConferenceExhibitors'}
          path={':id'}
          sort={[]}
          disableColumnFilter
          disableSearch
        />
      </Card>

      <Route path={`${CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH}/:exhibitorId`}>
        <ConferenceEditionExhibitorRoute onCrudAction={handleRefreshGrid} />
      </Route>
    </ItemPageContent>
  )
}
