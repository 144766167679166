import Stack, { StackProps } from '@mui/material/Stack'

/**
 * Renders a group of input fields.
 */
export default function InputFieldGroup({
  children,
  alignItems = 'center',
  direction = 'row',
  width = '100%',
  gap = 2,
  ...rest
}: StackProps) {
  return (
    <Stack
      alignItems={alignItems}
      direction={direction}
      gap={gap}
      width={width}
      {...rest}>
      {children}
    </Stack>
  )
}
