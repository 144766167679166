import {
  ARTICLE_TYPE_INTHENEWS,
  ARTICLE_TYPE_RESEARCH,
  IN_THE_NEWS,
  INDUSTRY_RESEARCH,
} from './articleTypes'
import {
  GET_ARTICLE_METHOD,
  GET_INDUSTRY_METHOD,
  GET_LEGAL_ENTITY_METHOD,
  GET_USER_METHOD,
  LIST_ARTICLES_METHOD,
  LIST_INDUSTRIES_METHOD,
  LIST_LEGAL_ENTITIES_METHOD,
} from './methods'

export const HOME_PATH = '/'
export const AUTH_CALLBACK_PATH = '/auth/callback'
export const LOGIN_PATH = '/login'

export const ITEM_PATH = '/item/:method/:id/:tab'
export const LIST_PATH = '/list/:method/:sort/:filter?'

export const LIST_INDUSTRIES_PATH = `/list/${LIST_INDUSTRIES_METHOD}/:sort/:filter?`
export const LIST_ARTICLES_INTHENEWS_PATH = `/list/${LIST_ARTICLES_METHOD}/:sort/${ARTICLE_TYPE_INTHENEWS}`
export const LIST_ARTICLES_RESEARCH_PATH = `/list/${LIST_ARTICLES_METHOD}/:sort/${ARTICLE_TYPE_RESEARCH}`
export const LIST_LEGAL_ENTITIES_PATH = `/list/${LIST_LEGAL_ENTITIES_METHOD}/:sort/:filter?`

export const INDUSTRY_PREVIEW_PATH = '/app/research/:id/:title/preview'
export const INDUSTRY_DETAIL_PATH = `/item/${GET_INDUSTRY_METHOD}/:id`
export const INDUSTRY_DETAIL_TAB_PATH = `${INDUSTRY_DETAIL_PATH}/:tab`
export const INDUSTRY_GENERAL_PATH = `${INDUSTRY_DETAIL_PATH}/general`
export const INDUSTRY_CHARTS_PATH = `${INDUSTRY_DETAIL_PATH}/charts`
export const INDUSTRY_SUMMARY_PATH = `${INDUSTRY_DETAIL_PATH}/summary`
export const INDUSTRY_SEGMENTS_PATH = `${INDUSTRY_DETAIL_PATH}/market`
export const INDUSTRY_OUTLOOK_PATH = `${INDUSTRY_DETAIL_PATH}/outlook`
export const INDUSTRY_ASSESSMENT_PATH = `${INDUSTRY_DETAIL_PATH}/assessment`
export const INDUSTRY_ASSETS_PATH = `${INDUSTRY_DETAIL_PATH}/assets`
export const INDUSTRY_SOURCES_PATH = `${INDUSTRY_DETAIL_PATH}/sources`

export const ARTICLE_INTHENEWS_DETAIL_PATH = `/item/${GET_ARTICLE_METHOD}/:id`
export const ARTICLE_INTHENEWS_TAB_PATH = `${ARTICLE_INTHENEWS_DETAIL_PATH}/:tab/${IN_THE_NEWS}`
export const ARTICLE_INTHENEWS_GENERAL_PATH = `${ARTICLE_INTHENEWS_DETAIL_PATH}/general/${IN_THE_NEWS}`
export const ARTICLE_INTHENEWS_ASSETS_PATH = `${ARTICLE_INTHENEWS_DETAIL_PATH}/assets/${IN_THE_NEWS}${LIST_PATH}`

export const ARTICLE_RESEARCH_DETAIL_PATH = `/item/${GET_ARTICLE_METHOD}/:id`
export const ARTICLE_RESEARCH_TAB_PATH = `${ARTICLE_RESEARCH_DETAIL_PATH}/:tab/${INDUSTRY_RESEARCH}`
export const ARTICLE_RESEARCH_GENERAL_PATH = `${ARTICLE_RESEARCH_DETAIL_PATH}/general/${INDUSTRY_RESEARCH}`
export const ARTICLE_RESEARCH_RESEARCH_PATH = `${ARTICLE_RESEARCH_DETAIL_PATH}/research/${INDUSTRY_RESEARCH}${LIST_PATH}`

export const LEGAL_ENTITY_PREVIEW_PATH = '/app/entity/:id/:name'
export const LEGAL_ENTITY_DETAIL_PATH = `/item/${GET_LEGAL_ENTITY_METHOD}/:id`
export const LEGAL_ENTITY_DETAIL_TAB_PATH = `${LEGAL_ENTITY_DETAIL_PATH}/:tab`
export const LEGAL_ENTITY_PROFILE_PATH = `${LEGAL_ENTITY_DETAIL_PATH}/profile`
export const LEGAL_ENTITY_REPORTS_PATH = `${LEGAL_ENTITY_DETAIL_PATH}/reports`
export const LEGAL_ENTITY_ASSETS_PATH = `${LEGAL_ENTITY_DETAIL_PATH}/assets${LIST_PATH}`

export const USER_DETAIL_PATH = `/item/${GET_USER_METHOD}/:id`
export const USER_DETAIL_TAB_PATH = `${USER_DETAIL_PATH}/:tab`
export const USER_PROFILE_PATH = `${USER_DETAIL_PATH}/profile`
export const USER_MATCHING_PATH = `${USER_DETAIL_PATH}/matching`
export const USER_STATISTICS_PATH = `${USER_DETAIL_PATH}/statistics`
export const USER_EMAIL_PATH = `${USER_DETAIL_PATH}/email`
export const USER_SECURITY_PATH = `${USER_DETAIL_PATH}/security`
