import { NoteListItem } from '@gain/rpc/cms-model'
import { ListFilter } from '@gain/rpc/list-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useParams } from 'react-router'

export type NoteResourceField = 'assetId' | 'dealId' | 'advisorId' | 'investorId'

export function useNotesListFilter(
  filterField: NoteResourceField,
  noteRelationId?: number
): Array<ListFilter<NoteListItem>> | null {
  const params = useParams<{ tab: string }>()

  if (!noteRelationId) {
    return null
  }

  return listFilters<NoteListItem>(
    listFilter('tab', '=', params.tab),
    listFilter(filterField, '=', noteRelationId)
  )
}
