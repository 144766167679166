import { useUserContext } from '@gain/modules/user'
import { NoteListItem } from '@gain/rpc/cms-model'
import { isAdministrator } from '@gain/utils/user'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import Avatar, { avatarClasses } from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import { useCallback, useRef, useState } from 'react'

import { useInputFormAPI } from '../../../common/input-fields'
import NoteEditor, { BlockNoteEditor, filterEditorBlocks } from '../note-editor'
import { stringNameToAvatarProps } from './note-utils'

export interface NoteProps {
  note: NoteListItem
  onDelete: () => void
}

const noteClasses = generateUtilityClasses('Note', ['root', 'deleteAction'])

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`& .${noteClasses.deleteAction}`]: {
    opacity: 0,
    willChange: 'opacity',
    transition: theme.transitions.create(['opacity']),
    color: theme.palette.error.main,
  },

  [`&:hover .${noteClasses.deleteAction}`]: {
    opacity: 1,
  },
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  margin: theme.spacing(1.5),

  [`& .${avatarClasses.root}`]: {
    width: 35,
    height: 35,
    fontSize: 18,
  },

  [`& .${typographyClasses.body1}`]: {
    lineHeight: 1.3,
  },
}))

const StyledLoadingButton = styled(LoadingButton)({
  minWidth: 30,
})

export default function Note({ note, onDelete }: NoteProps) {
  const [editing, setEditing] = useState(false)
  const editorRef = useRef<BlockNoteEditor | null>(null)
  const { userProfile } = useUserContext()

  const inputFormAPI = useInputFormAPI({
    updateMethod: 'cms.updateNote',
    deleteMethod: 'cms.deleteNote',
  })

  const canEdit = userProfile.id === note.userId || isAdministrator(userProfile.role)

  const initialContent = JSON.parse(note.content)

  const handleUpdateNote = useCallback(async () => {
    const editor = editorRef.current
    if (editor) {
      editor.replaceBlocks(editor.document, filterEditorBlocks(editor))
      await inputFormAPI.patch(note.id, {
        content: JSON.stringify(editor.document),
      })
    }

    setEditing(false)
  }, [note.id, inputFormAPI])

  const handleDeleteNote = useCallback(async () => {
    await inputFormAPI.delete(note.id)

    onDelete()
  }, [inputFormAPI, note.id, onDelete])

  const handleEditNote = useCallback(() => {
    if (!canEdit) {
      return
    }

    setEditing(true)

    // Give the ui some time to make the editor editable
    setTimeout(() => {
      const editor = editorRef.current

      if (editor) {
        const lastBlock = editor.document[editor.document.length - 1]
        if (lastBlock) {
          editor.setTextCursorPosition(lastBlock, 'end')
        }
        editor.focus()
      }
    }, 50)
  }, [canEdit])

  return (
    <StyledRoot className={noteClasses.root}>
      <Header>
        <Avatar
          variant={'rounded'}
          {...stringNameToAvatarProps(note.fullName)}
        />

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}>
          <Stack
            height={'100%'}
            justifyContent={'center'}>
            <Typography variant={'body1'}>{note.fullName}</Typography>
            <Typography variant={'caption'}>
              {format(parseISO(note.createdAt), 'HH:mm, d MMMM, yyyy')}
            </Typography>
          </Stack>

          {canEdit && (
            <Stack direction={'row'}>
              <StyledLoadingButton
                className={noteClasses.deleteAction}
                loading={inputFormAPI.deleting}
                onClick={handleDeleteNote}>
                <DeleteIcon />
              </StyledLoadingButton>

              <StyledLoadingButton
                loading={inputFormAPI.saving || inputFormAPI.creating}
                onClick={editing ? handleUpdateNote : handleEditNote}>
                {!editing && <EditIcon />}
                {editing && <SaveIcon />}
              </StyledLoadingButton>
            </Stack>
          )}
        </Stack>
      </Header>

      <NoteEditor
        ref={editorRef}
        initialContent={initialContent}
        readonly={!editing}
      />

      <Divider />
    </StyledRoot>
  )
}
