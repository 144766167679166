import { ChipOption, getOption } from '@gain/rpc/shared-model'
import Chip from '@mui/material/Chip'

import { SWR_ROW_STATUS_OPTIONS, SwrRowStatusType } from './swr-row-status-utils'

interface SWRRowStatusProps {
  status?: SwrRowStatusType
}

export default function SWRRowStatus({ status }: SWRRowStatusProps) {
  const chipOption = getOption<SwrRowStatusType, ChipOption<SwrRowStatusType>>(
    SWR_ROW_STATUS_OPTIONS,
    status as SwrRowStatusType
  )

  return (
    <Chip
      color={chipOption?.color}
      label={chipOption?.label}
      size={'small'}
      variant={'outlined'}
    />
  )
}
