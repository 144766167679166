import { useRpcClient } from '@gain/api/swr'
import { CustomerUsageReportType, ReportFormat, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError, listSort } from '@gain/rpc/utils'
import { useDialogState } from '@gain/utils/dialog'
import { downloadUrl } from '@gain/utils/download'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { format as formatDate } from 'date-fns/format'
import { startOfDay } from 'date-fns/startOfDay'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import {
  InputFieldAutoComplete,
  InputFieldDate,
  InputFieldSelect,
  InputFormProvider,
} from '../../../common/input-fields'

export default function ExportCustomerUsage() {
  const [open, openDialog, closeDialog] = useDialogState()
  const { enqueueSnackbar } = useSnackbar()

  const rpcClient = useRpcClient<RpcMethodMap>()
  const form = useForm({
    defaultValues: {
      customers: [],
      type: CustomerUsageReportType.CustomerUsageOverall,
      format: ReportFormat.Csv,
      from: null,
      to: null,
    },
  })

  const handleExport = () => {
    form.handleSubmit(async (values) => {
      try {
        const url = await rpcClient({
          method: 'customer.getCustomerUsageReport',
          params: {
            customerIds: values.customers.map(({ id }) => id),
            type: values.type,
            format: values.format,
            from: values.from
              ? formatDate(startOfDay(values.from), 'yyyy-MM-dd') + 'T00:00:00Z'
              : values.from,
            to: values.to ? formatDate(values.to, 'yyyy-MM-dd') + 'T23:59:59Z' : values.to,
          },
        })

        form.reset()
        downloadUrl(url)
      } catch (error) {
        if (isJsonRpcError(error)) {
          enqueueSnackbar(error.message, {
            key: 'export-usage-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }
      }

      closeDialog()
    })()
  }

  return (
    <>
      <Button
        onClick={openDialog}
        variant={'contained'}>
        Export usage report
      </Button>

      <Dialog
        maxWidth={'sm'}
        onClose={closeDialog}
        open={open}
        fullWidth>
        <DialogTitle>Export usage report</DialogTitle>
        <DialogContent>
          <InputFormProvider form={form}>
            <Stack
              py={2}
              spacing={2}>
              <InputFieldAutoComplete
                defaultSort={[listSort('name', 'asc')]}
                helperText={'Leave blank to export usage for all customers'}
                label={'Customers'}
                labelProp={'name'}
                method={'customer.listCustomers'}
                name={'customers'}
                valueProp={'id'}
                multiple
              />

              <InputFieldSelect
                label={'Report type'}
                name={'type'}
                options={[
                  {
                    value: CustomerUsageReportType.CustomerUsageOverall,
                    label: 'Usage overall',
                  },
                  {
                    value: CustomerUsageReportType.CustomerUsageWeekly,
                    label: 'Usage per week',
                  },
                ]}
              />

              <InputFieldDate
                label={'From'}
                name={'from'}
              />

              <InputFieldDate
                label={'To'}
                name={'to'}
              />
            </Stack>
          </InputFormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={form.formState.isSubmitting}
            onClick={handleExport}>
            Export
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
