import { UPLOAD_URL } from '@gain/api/cms/hooks'
import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { connectionFailed, serverError, unauthorized } from '../action/server'
import {
  uploadFileSuccess,
  UPLOAD_FILE,
  UPLOAD_FILE_CANCELLED,
  UPLOAD_FILE_SUCCESS,
} from '../action/uploadFile'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(UPLOAD_FILE),
      mergeMap((action) =>
        // Please note that this isn't an RPC call, therefore it doesn't use
        // the rpc operator, but the bare-bones ajax operator.
        ajax({
          url: UPLOAD_URL,
          method: 'POST',
          headers: {
            'Content-Type': action.file.type,
            'X-Filename': encodeURIComponent(action.file.name),
            'X-Secure': action.secure,
          },
          withCredentials: true,
          body: action.file,
        }).pipe(
          map(({ response }) => uploadFileSuccess(response, action.next)),
          catchError((error) => {
            if (error.status === 401) {
              return of(unauthorized())
            } else if (error.status === 413) {
              // Cloud Run only allows 32 MiB uploads when using HTTP/1
              const cloudRunMaxError =
                'Failed to upload, file too large: exceeded maximum of 32 MiB'
              return of(serverError(error.status, cloudRunMaxError))
            } else if (error.status === 400) {
              return of(serverError(error.status, error.response))
            }
            return of(connectionFailed(error))
          }),
          takeUntil(action$.pipe(ofType(UPLOAD_FILE_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(UPLOAD_FILE_SUCCESS),
      filter(({ next }) => isFunction(next)),
      map(({ response, next }) => next(response))
    )
  )
