import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ElementType } from 'react'

import { ReactComponent as ArrowLink } from '../../assets/icons/arrow-link.svg'

const wrapIcon = (component: ElementType) =>
  function (props: SvgIconProps) {
    return (
      <SvgIcon
        component={component}
        viewBox={'0 0 14 14'}
        {...props}
      />
    )
  }

export const ArrowLinkIcon = wrapIcon(ArrowLink)
