import { Customer, CustomerListItem } from '@gain/rpc/cms-model'
import CircularProgress from '@mui/material/CircularProgress'
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
} from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams'
import { ReactElement } from 'react'

import { useInputFormContext } from '../../../common/input-fields'
import { useSwrDataGrid } from '../../../common/swr-data-grid'
import DataGridActionsArchive from '../../../layout/data-grid/data-grid-actions-archive'
import DataGridActionsDelete from '../../../layout/data-grid/data-grid-actions-delete'
import { ROUTE_CUSTOMERS_PATH } from '../route-customers-path'

export default function CustomerActions({
  row,
}: Pick<
  GridRowParams<CustomerListItem | Customer>,
  'row'
>): ReactElement<GridActionsCellItemProps>[] {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()

  // Show loader if we are rendering inside the grid and doing something
  if (inputFormContext.busy && swrDataGrid.api) {
    return [
      <GridActionsCellItem
        icon={<CircularProgress size={16} />}
        label={''}
      />,
    ]
  }

  return [
    <DataGridActionsArchive
      key={'archive'}
      isArchived={row.deactivated}
      label={row.deactivated ? 'Activate customer' : 'Deactivate customer'}
      type={'customer'}
      canBeArchived
      showInMenu
    />,

    !inputFormContext.disabled && (
      <DataGridActionsDelete
        key={'delete'}
        path={ROUTE_CUSTOMERS_PATH}
        showInMenu
      />
    ),
  ].filter(Boolean) as ReactElement<GridActionsCellItemProps>[]
}
